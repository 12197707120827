/* You can add global styles to this file, and also import other style files */
.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.font-35 {
  font-size: 35px !important;
}

.font-25 {
  font-size: 25px !important;
}

.pointer {
  cursor: pointer;
}

.pac-container {
  z-index: 1055;
}


@media (min-width: 576px) {
  .dropdown-lg {
      width: fit-content;
  }
}
